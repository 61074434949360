<template>
  <b-overlay :show="loading">
    <b-card no-body>
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col><div>Menu</div></b-col>
            <b-col>
              <div align="right" v-if="allowCreate()">
                <b-button variant="outline-primary" v-b-modal.modal-sm @click="ModalShow()" class="btn-icon"> <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button>
              </div>
              <b-modal v-model="Modal" id="modal-tambah" no-close-on-backdrop size="sm" :title="titleModal">
                <b-card-text>
                  <b-form>
                    <b-form-group label="Nama" label-for="nama">
                      <b-form-input id="nama" v-model="form.name" placeholder="Nama" />
                    </b-form-group>
                    <b-form-group label="Icon (Feather)" label-for="icon">
                      <v-select v-model="form.icon" :options="icons" label="text" />
                    </b-form-group>
                    <b-form-group label="URL Level" label-for="url_level">
                      <v-select v-model="filterLevel" :options="levelName" label="text" />
                    </b-form-group>
                    <b-form-group label="URL" label-for="url">
                      <v-select v-model="form.url" :options="opsiUrl" label="text" />
                    </b-form-group>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="outline-success" class="mr-1 btn-icon" @click.prevent="Tambah">
                      <feather-icon icon="SaveIcon" class="mr-25" />{{ label || "Tambah" }}
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="float-right btn-icon" @click="Modal = false"> <feather-icon icon="LogOutIcon" class="mr-25" />Tutup </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group label="Sort" label-cols-sm="2" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-50">
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-30">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Search......" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="data"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(icon)="data">
                  <feather-icon :icon="data.value" class="align-middle" />
                  {{ data.value }}
                </template>
                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-button v-if="allowUpdate()" title="Lihat Sub Menu" variant="outline-success" class="btn-icon" @click.prevent="viewSubmenu(row.item)"> <feather-icon icon="ListIcon" /></b-button>
                  <b-button v-if="allowUpdate()" variant="outline-info" class="btn-icon mx-1" @click="ModalUbah(row.item)"> <feather-icon icon="EditIcon" /></b-button>
                  <b-button v-if="allowDelete()" variant="outline-danger" class="btn-icon" @click="ModalHapus(row.item)"> <feather-icon icon="TrashIcon" /></b-button>
                </template>
                <!-- A custom formatted column -->
                <template #cell(Popularity)="data">
                  <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
                </template>

                <template #cell(order_status)="data">
                  <b-badge pill :variant="data.value.variant">
                    {{ data.value.status }}
                  </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(price)="data">
                  {{ "$" + data.value }}
                </template>

                <template #cell(url)="data">
                  {{ data.value }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ToastificationContent,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      filterLevel: null,
      levelName: [
        {
          text: "Admin",
        },
        {
          text: "Siswa",
        },
      ],
      activeAction: null,
      label: "Simpan",
      //Data
      data: [],
      form: {
        name: null,
        icon: "",
        url: null,
        i18n: "",
        parent_id: 0,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama", sortable: true },
        { key: "icon", label: "Icon", sortable: true },
        { key: "url", label: "Url", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Nama" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      icons: [
        "ActivityIcon",
        "AirplayIcon",
        "AlertCircleIcon",
        "AlertOctagonIcon",
        "AlertTriangleIcon",
        "AlignCenterIcon",
        "AlignJustifyIcon",
        "AlignLeftIcon",
        "AlignRightIcon",
        "AnchorIcon",
        "ApertureIcon",
        "ArchiveIcon",
        "ArrowDownCircleIcon",
        "ArrowDownLeftIcon",
        "ArrowDownRightIcon",
        "ArrowDownIcon",
        "ArrowLeftCircleIcon",
        "ArrowLeftIcon",
        "ArrowRightCircleIcon",
        "ArrowRightIcon",
        "ArrowUpCircleIcon",
        "ArrowUpLeftIcon",
        "ArrowUpRightIcon",
        "ArrowUpIcon",
        "AtSignIcon",
        "AwardIcon",
        "BarChart2Icon",
        "BarChartIcon",
        "BatteryChargingIcon",
        "BatteryIcon",
        "BellOffIcon",
        "BellIcon",
        "BluetoothIcon",
        "BoldIcon",
        "BookOpenIcon",
        "BookIcon",
        "BookmarkIcon",
        "BoxIcon",
        "BriefcaseIcon",
        "CalendarIcon",
        "CameraOffIcon",
        "CameraIcon",
        "CastIcon",
        "CheckCircleIcon",
        "CheckSquareIcon",
        "CheckIcon",
        "ChevronDownIcon",
        "ChevronLeftIcon",
        "ChevronRightIcon",
        "ChevronUpIcon",
        "ChevronsDownIcon",
        "ChevronsLeftIcon",
        "ChevronsRightIcon",
        "ChevronsUpIcon",
        "ChromeIcon",
        "CircleIcon",
        "ClipboardIcon",
        "ClockIcon",
        "CloudDrizzleIcon",
        "CloudLightningIcon",
        "CloudOffIcon",
        "CloudRainIcon",
        "CloudSnowIcon",
        "CloudIcon",
        "CodeIcon",
        "CodepenIcon",
        "CodesandboxIcon",
        "CoffeeIcon",
        "ColumnsIcon",
        "CommandIcon",
        "CompassIcon",
        "CopyIcon",
        "CornerDownLeftIcon",
        "CornerDownRightIcon",
        "CornerLeftDownIcon",
        "CornerLeftUpIcon",
        "CornerRightDownIcon",
        "CornerRightUpIcon",
        "CornerUpLeftIcon",
        "CornerUpRightIcon",
        "CpuIcon",
        "CreditCardIcon",
        "CropIcon",
        "CrosshairIcon",
        "DatabaseIcon",
        "DeleteIcon",
        "DiscIcon",
        "DivideCircleIcon",
        "DivideSquareIcon",
        "DivideIcon",
        "DollarSignIcon",
        "DownloadCloudIcon",
        "DownloadIcon",
        "DribbbleIcon",
        "DropletIcon",
        "Edit2Icon",
        "Edit3Icon",
        "EditIcon",
        "ExternalLinkIcon",
        "EyeOffIcon",
        "EyeIcon",
        "FacebookIcon",
        "FastForwardIcon",
        "FeatherIcon",
        "FigmaIcon",
        "FileMinusIcon",
        "FilePlusIcon",
        "FileTextIcon",
        "FileIcon",
        "FilmIcon",
        "FilterIcon",
        "FlagIcon",
        "FolderMinusIcon",
        "FolderPlusIcon",
        "FolderIcon",
        "FramerIcon",
        "FrownIcon",
        "GiftIcon",
        "GitBranchIcon",
        "GitCommitIcon",
        "GitMergeIcon",
        "GitPullRequestIcon",
        "GithubIcon",
        "GitlabIcon",
        "GlobeIcon",
        "GridIcon",
        "HardDriveIcon",
        "HashIcon",
        "HeadphonesIcon",
        "HeartIcon",
        "HelpCircleIcon",
        "HexagonIcon",
        "HomeIcon",
        "ImageIcon",
        "InboxIcon",
        "InfoIcon",
        "InstagramIcon",
        "ItalicIcon",
        "KeyIcon",
        "LayersIcon",
        "LayoutIcon",
        "LifeBuoyIcon",
        "Link2Icon",
        "LinkIcon",
        "LinkedinIcon",
        "ListIcon",
        "LoaderIcon",
        "LockIcon",
        "LogInIcon",
        "LogOutIcon",
        "MailIcon",
        "MapPinIcon",
        "MapIcon",
        "Maximize2Icon",
        "MaximizeIcon",
        "MehIcon",
        "MenuIcon",
        "MessageCircleIcon",
        "MessageSquareIcon",
        "MicOffIcon",
        "MicIcon",
        "Minimize2Icon",
        "MinimizeIcon",
        "MinusCircleIcon",
        "MinusSquareIcon",
        "MinusIcon",
        "MonitorIcon",
        "MoonIcon",
        "MoreHorizontalIcon",
        "MoreVerticalIcon",
        "MousePointerIcon",
        "MoveIcon",
        "MusicIcon",
        "Navigation2Icon",
        "NavigationIcon",
        "OctagonIcon",
        "PackageIcon",
        "PaperclipIcon",
        "PauseCircleIcon",
        "PauseIcon",
        "PenToolIcon",
        "PercentIcon",
        "PhoneCallIcon",
        "PhoneForwardedIcon",
        "PhoneIncomingIcon",
        "PhoneMissedIcon",
        "PhoneOffIcon",
        "PhoneOutgoingIcon",
        "PhoneIcon",
        "PieChartIcon",
        "PlayCircleIcon",
        "PlayIcon",
        "PlusCircleIcon",
        "PlusSquareIcon",
        "PlusIcon",
        "PocketIcon",
        "PowerIcon",
        "PrinterIcon",
        "RadioIcon",
        "RefreshCcwIcon",
        "RefreshCwIcon",
        "RepeatIcon",
        "RewindIcon",
        "RotateCcwIcon",
        "RotateCwIcon",
        "RssIcon",
        "SaveIcon",
        "ScissorsIcon",
        "SearchIcon",
        "SendIcon",
        "ServerIcon",
        "SettingsIcon",
        "Share2Icon",
        "ShareIcon",
        "ShieldOffIcon",
        "ShieldIcon",
        "ShoppingBagIcon",
        "ShoppingCartIcon",
        "ShuffleIcon",
        "SidebarIcon",
        "SkipBackIcon",
        "SkipForwardIcon",
        "SlackIcon",
        "SlashIcon",
        "SlidersIcon",
        "SmartphoneIcon",
        "SmileIcon",
        "SpeakerIcon",
        "SquareIcon",
        "StarIcon",
        "StopCircleIcon",
        "SunIcon",
        "SunriseIcon",
        "SunsetIcon",
        "TabletIcon",
        "TagIcon",
        "TargetIcon",
        "TerminalIcon",
        "ThermometerIcon",
        "ThumbsDownIcon",
        "ThumbsUpIcon",
        "ToggleLeftIcon",
        "ToggleRightIcon",
        "ToolIcon",
        "Trash2Icon",
        "TrashIcon",
        "TrelloIcon",
        "TrendingDownIcon",
        "TrendingUpIcon",
        "TriangleIcon",
        "TruckIcon",
        "TvIcon",
        "TwitchIcon",
        "TwitterIcon",
        "TypeIcon",
        "UmbrellaIcon",
        "UnderlineIcon",
        "UnlockIcon",
        "UploadCloudIcon",
        "UploadIcon",
        "UserCheckIcon",
        "UserMinusIcon",
        "UserPlusIcon",
        "UserXIcon",
        "UserIcon",
        "UsersIcon",
        "VideoOffIcon",
        "VideoIcon",
        "VoicemailIcon",
        "Volume1Icon",
        "Volume2Icon",
        "VolumeXIcon",
        "VolumeIcon",
        "WatchIcon",
        "WifiOffIcon",
        "WifiIcon",
        "WindIcon",
        "XCircleIcon",
        "XOctagonIcon",
        "XSquareIcon",
        "XIcon",
        "YoutubeIcon",
        "ZapOffIcon",
        "ZapIcon",
        "ZoomInIcon",
        "ZoomOutIcon",
      ],
      url: [
        {
          type: "Siswa",
          text: "Statistik",
          name: "statistik",
          url: "/statistik",
        },
        {
          type: "Siswa",
          text: "Tryout",
          name: "tryout",
          url: "/tryout",
        },
        {
          type: "Siswa",
          text: "Raport",
          name: "raport",
          url: "/raport",
        },
        {
          type: "Siswa",
          text: "SNBP",
          name: "snbp",
          url: "/snbp",
        },
        {
          type: "Siswa",
          text: "Profil",
          name: "profil",
          url: "/profil",
        },
        {
          type: "Siswa",
          text: "Ebook",
          name: "ebook",
          url: "/ebook",
        },
        {
          type: "Siswa",
          text: "Paket Tryout",
          name: "paket_tryout",
          url: "/paket_tryout",
        },
        {
          type: "Siswa",
          text: "Billing",
          name: "billing",
          url: "/billing",
        },
        {
          type: "Siswa",
          text: "Panduan",
          name: "panduan",
          url: "/panduan",
        },
        {
          type: "Siswa",
          text: "FAQ",
          name: "faq",
          url: "/faq",
        },
        {
          type: "Admin",
          text: "Dashboard",
          name: "admin-dashboard",
          url: "/master-admin/dashboard",
        },
        {
          type: "Admin",
          text: "Customer/Student",
          name: "admin-pengguna-customer",
          url: "/master-admin/customer",
        },
        {
          type: "Admin",
          text: "Admin",
          name: "admin-pengguna",
          url: "/master-admin/pengguna",
        },
        {
          type: "Admin",
          text: "Jadwal",
          name: "admin-jadwal",
          url: "/master-admin/jadwal-admin",
        },
        {
          type: "Admin",
          text: "Ujian/Subtest",
          name: "admin-ujian",
          url: "/master-admin/ujian-admin",
        },
        {
          type: "Admin",
          text: "Bank Soal",
          name: "admin-bank-soal",
          url: "/master-admin/bank-soal",
        },
        {
          type: "Admin",
          text: "Bank Video",
          name: "admin-bank-video",
          url: "/master-admin/bank-video",
        },
        {
          type: "Admin",
          text: "Paket Tryout",
          name: "admin-paket-tryout",
          url: "/master-admin/paket-tryout",
        },
        {
          type: "Admin",
          text: "SNBP",
          name: "admin-snbp",
          url: "/master-admin/snbp",
        },
        {
          type: "Admin",
          text: "Rekening",
          name: "admin-payment",
          url: "/master-admin/payment",
        },
        {
          type: "Admin",
          text: "Transaksi Paket",
          name: "admin-transaksi",
          url: "/master-admin/transaksi",
        },
        {
          type: "Admin",
          text: "Transaksi SNBP",
          name: "admin-transaksi-snbp",
          url: "/master-admin/transaksi-snbp",
        },
        {
          type: "Admin",
          text: "Transaksi Ebook",
          name: "admin-transaksi-ebook",
          url: "/master-admin/transaksi-ebook",
        },
        {
          type: "Admin",
          text: "Voucher",
          name: "admin-voucher",
          url: "/master-admin/voucher",
        },
        {
          type: "Admin",
          text: "E-book",
          name: "admin-ebook",
          url: "/master-admin/ebook",
        },
        {
          type: "Admin",
          text: "Kategori Ebook",
          name: "admin-kategori-paket-ebook",
          url: "/master-admin/kategori-paket-ebook",
        },
        {
          type: "Admin",
          text: "Rumpun",
          name: "admin-rumpun",
          url: "/master-admin/rumpun",
        },
        {
          type: "Admin",
          text: "Universitas",
          name: "admin-universitas",
          url: "/master-admin/universitas",
        },
        {
          type: "Admin",
          text: "Sekolah",
          name: "admin-sekolah",
          url: "/master-admin/sekolah",
        },
        {
          type: "Admin",
          text: "Mata Pelajaran",
          name: "admin-mata-pelajaran",
          url: "/master-admin/mata-pelajaran",
        },
        {
          type: "Admin",
          text: "Banner",
          name: "admin-banner",
          url: "/master-admin/banner",
        },
        {
          type: "Admin",
          text: "Kategori Artikel & Halaman",
          name: "admin-kategori-artikel",
          url: "/master-admin/kategori-artikel",
        },
        {
          type: "Admin",
          text: "Artikel",
          name: "admin-artikel",
          url: "/master-admin/artikel",
        },
        {
          type: "Admin",
          text: "Halaman",
          name: "admin-halaman",
          url: "/master-admin/halaman",
        },
        {
          type: "Admin",
          text: "Panduan",
          name: "admin-panduan",
          url: "/master-admin/panduan",
        },
        {
          type: "Admin",
          text: "FAQ",
          name: "admin-faq",
          url: "/master-admin/faq",
        },
        {
          type: "Admin",
          text: "Role Management",
          name: "admin-role-management",
          url: "/master-admin/role-management",
        },
        {
          type: "Admin",
          text: "Level",
          name: "admin-level",
          url: "/master-admin/level",
        },
        {
          type: "Admin",
          text: "Menu",
          name: "admin-menu",
          url: "/master-admin/menu",
        },
      ],
      opsiUrl: [],
      loading: false,
    };
  },
  watch: {
    filterLevel(val) {
      if (val) {
        var filter = this.url.filter((item) => {
          return item.type == val.text;
        });
      } else {
        var filter = [];
      }

      this.opsiUrl = filter;
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    viewSubmenu(menu) {
      this.$store.commit("menu/SET_CURRENT_MENU", menu);
      this.$router.push(`/master-admin/submenu/${menu.id}`);
    },
    resetForm() {
      this.form = {
        name: null,
        icon: "",
        url: null,
        i18n: "",
        parent_id: 0,
      };
    },

    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.resetForm();
      this.activeAction = "tambah";
      this.titleModal = "Tambah Menu";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Menu";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("menu/createUpdateDelete", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "" || this.form.icon == "" || this.form.url == null || this.form.i18n == null) {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.name,
        icon: this.form.icon,
        url: this.form.url.url,
        route_name: this.form.url.name,
        i18n: this.form.name,
        parent_id: 0,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("menu/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.form.name = null;
          this.form.name = null;
          this.form.icon = "";
          this.form.url = null;
          this.form.i18n = "";
          this.form.parent_id = null;
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Menu berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const payload = {};
      this.$store
        .dispatch("menu/index", payload)
        .then((response) => {
          this.loading = false;
          let data = response.data.data;
          this.data = data;
          this.totalRows = this.data.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  created() {},
  async mounted() {
    this.getData();
  },
};
</script>

<style></style>
